export const nfts = {
	"skullys": {
		"abi": require("../abis/skullys.json"),
		"address": "0x25ff0d27395A7AAD578569f83E30C82a07b4ee7d",
		"claim-abi": require("../abis/cult.json"),
		"claim-address": "0x4Ed9C2aba77141433c462B8EE835B7fC39Ec449A",
		"claim-icon": require("../images/token-icons/cult.jpg_medium"),
		"img": require("../images/skully-img.png")
	},
	"popskullys": {
		"abi": require("../abis/popskullys.json"),
		"address": "0x1b60B6daA371F5066bd8C1DC032627bf1f4E95df",
		"claim-abi": require("../abis/xcult.json"),
		"claim-address": "0x718670c88F2daa222d44908C1c253253FF96aE21",
		"claim-icon": require("../images/token-icons/xcult.jpg_medium"),
		"img": require("../images/popskully-img.png")
	},
	"ghostlys": {
		"abi": require("../abis/skullys.json"),
		"address": "0x4EaB37d5C62fa3bff8F7A5FFce6a88cFC098749C",
		"claim-abi": require("../abis/xcult.json"),
		"claim-address": "0x718670c88F2daa222d44908C1c253253FF96aE21",
		"claim-icon": require("../images/token-icons/xcult.jpg_medium"),
		"img": require("../images/ghostly-img.png")
	},
	"items": {
		"abi": require("../abis/CultItems.json"),
		"address": "0xc8a2D2D06e9E0b7eB49257776F3c37C9a2050494"
	},
	"resources": {
		"abi": require("../abis/CultResources.json"),
		"address": "0xb363884d2393764F9721B16aaB3C0289576FF243"
	},
	"crystals": {
		"abi": require("../abis/crystals.json"),
		"address": "0xeE5c70923934232a4a1F94462F9955e2B8A58792"
	},
	"famdustfarm": {
		"abi": require("../abis/famdustfarm.json"),
		"address": "0xa40FE2e9ac383eC6ad3BB4d3FAFED32CC653c0FF"
	},
	"shrines": {
		"abi": require("../abis/shrines.json"),
		"address": "0xF26c109CF5990675c088D2694810EFc38E7a28F7"
	},
	"AlphaShrineRouter": {
		"abi": require("../abis/AlphaShrineRouter.json"),
		"address": "0xC9aEf31b71734310700027e11f2341953baEE9c2"
	},
	"shrineCrystals": {
		"abi": require("../abis/crystals.json"),
		"address": "0xaaa45F719C3c519dBd890e34E83F26a9682DD4Df"
	},
	"crystalStaking": {
		"abi": require("../abis/CrystalStaking.json"),
		"address": "0xdF166b21b8BDfEcb80fb2E2811C4B6b70e34f7D0"
	}
}