import './App.scss';
import React from 'react';
import Web3 from 'web3'; 
import { chainMap, enforceChain } from './tools/ChainTools.js'
import { tokens } from './configs/CultTokensConfig.js'
import { nfts } from './configs/CultNFTConfig.js'

//import door from './imgs/door.gif'
//import famgif from './imgs/fam.gif'

import door from './images/forge.png'
//import famgif from './imgs/famslow.gif'

//import door from './imgs/doorslowbig.gif'
import famgif from './imgs/famsmooth.gif'

import dustimg from './images/dust.png'
import karmacrystal from './images/karmacrystal.jpg'
import karmaimg from './images/karma.jpg'
import famcrystal from './images/famcrystal.jpg'
import figure from './imgs/figure.gif'
const fs = (bal, dec = 18) => {
  let x = bal.toString()
  let digits, decimals
  let L = "0", R = "0"
  let output
  //console.log(x.length, dec)
  if (x.length > dec) {
    digits = x.length - dec
    L = x.slice(0,digits)
    R = x.slice(digits)
  } else {
    decimals = dec - x.length
    R = "0".repeat(decimals).concat(x)
  }
  output = L.concat(".").concat(R)
  output = new Intl.NumberFormat().format(output)
  return output
}
function App() {
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");

 
  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (!connected) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      }
  }

  
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
        window.ethereum.on('chainChanged', handleChainChange);
      };
    }
  }, [connected]);



  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])



  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  

  const [isDisabled, setDisabled] = React.useState(false);

  // this is a reference to the input field
  const theInputRef = React.createRef();

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.ftm.tools'));




  var candle = new web3.eth.Contract(
    tokens["candle"]["abi"], 
    tokens["candle"]["address"])
  var resources = new web3.eth.Contract(
    nfts["resources"]["abi"],
    nfts["resources"]["address"]
    )

  

  const getTokenBalance = (token, setState) => {
    token.methods.balanceOf(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=> {
        console.log("token req", res)
        setState(res)
      }).catch((err) => {
        console.log(err)
      })
  }

  const getResourceBalance = (id, setState) => {
    resources.methods.balanceOf(window.ethereum.selectedAddress, id)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        console.log("resource req", res)
        setState(res)
      }).catch((err)=> {
        console.log(err)
      })
  }

  const letItRip = () => {
    console.log("let it rip")

    

   

  }

  const [livePage, setLivePage] = React.useState("home");

  const nav = (name) => {
    return () => {
      setLivePage(name);
    }
  }

  const [modalOn, setModalOn] = React.useState(false)
  const [activeModalPage, setActiveModalPage] = React.useState("")

  const setModal = (page) => {
    return () => {
      setModalOn(true)
      setActiveModalPage(page)
    }
  }
  const setModalKarma = () => {
    return () => {
      setModalOn(true)
      setActiveModalPage("karma-crystal")
      checkApprovalThen(()=>{})
      getCrystalBalance(1, setBalKarmaCrystal)
      getResourceBalance(1, setBalKarma)
    }
  }

  const [balCandle, setBalCandle] = React.useState(0)
  const [balKarma, setBalKarma] = React.useState(0)
  const loadBalances = () => {
    getTokenBalance(candle, setBalCandle)
    getResourceBalance(1, setBalKarma)
    getCrystalBalance(1, setBalKarmaCrystal)
    getResourceBalance(2, setBalFD)
    getShrineCrystalBalance(101, setBalFamCrystal)
  }

  const [lastMined, setLastMined] = React.useState(0)
  const lastMinedResource = () =>{
    resources.methods.lastMinedResource(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setLastMined(res)
      }).catch((err)=>{
        console.log(err)
      })
  }



  const [startBlock, setStartBlock] = React.useState(0)
  const startMiningBlock = () => {
    resources.methods.startMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setStartBlock(res)
      }).catch((err)=>{
        console.log(err)
      })
  }

  const [stopBlock, setStopBlock] = React.useState(0)
  const stopMiningBlock = () => {
    resources.methods.stopMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setStopBlock(res)
      }).catch((err)=>{
        console.log(err)
      })
  }

  const [currentBlock, setCurrentBlock] = React.useState(0)
  const getCurrentBlock = () => {
    web3.eth.getBlockNumber()
    .then((res)=>{
      console.log(res)
      setCurrentBlock(res)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const [userKarmaRate, setUserKarmaRate] = React.useState(0)
  const getUserKarmaRate = () => {
    resources.methods.getUserRate(window.ethereum.selectedAddress, 1)
      .call({from: window.ethereum.selectedAddress})
        .then((res)=>{
          setUserKarmaRate(res)
        }).catch((err)=>{
          console.log(err)
        })
  }

  const isMining = () => {

    if (startBlock > 0) {
      return true
    } else {
      return false
    }

  }

  const isWaiting = () => {
    if (stopBlock > 0) {
      return true
    } else {
      return false
    }
  }

  const checkStatus = () => {
    lastMinedResource()
    startMiningBlock()
    stopMiningBlock()
    getCurrentBlock()
    loadBalances()
    getUserKarmaRate()
    startMiningBlockFD()
    stopMiningBlockFD()

  }

  const resourceTimes = [0,1]
  const resourceDelay = [0,10000]

  const [status, setStatus] = React.useState("init")

  React.useEffect(()=>{
    if (isWaiting()) {
      // user is either on cooldown
      console.log(stopBlock, resourceDelay[1])
      if (Number(stopBlock) + resourceDelay[1] > Number(currentBlock)) {
        setStatus("resting")
      } else { // or ready to mine again
        setStatus("ready")
      }
    } 

    if (isMining()) {
      // user is either mining and has time left
      if (Number(startBlock) + resourceTimes[1] > Number(currentBlock)) {
        setStatus("mining")
      } else { // or is finished mining but hasn't triggered rest()
        setStatus("tired")
      }
      
    }

    if (!isWaiting() && !isMining() ) {
      // user first time
      setStatus("ready")
    }
  },[startMiningBlock, stopMiningBlock])

  const mine = (id) => {
    return () => {
      let data = resources.methods.mine(id).encodeABI()
      setIsPending(true)
      window.ethereum.request(
        {
        method: "eth_sendTransaction",
        // The following sends an EIP-1559 transaction. Legacy transactions are also supported.
        params: [
          {
            // The user's active address.
            from: window.ethereum.selectedAddress,
            // Required except during contract publications.
            to: nfts["resources"]["address"],
            // Only required to send ether to the recipient from the initiating external account.
            value: 0,
            data: data,
          },
        ],
      }

        ).then((res)=>{
          
          setIsPending(false)
        }).catch((err)=>{
          setIsPending(false)
          console.log(err)
        })
    }
  }

  const rest = () => {
    let data = resources.methods.rest().encodeABI()
    window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["resources"]["address"],
            value: 0,
            data: data,
          },

        ],
    
      }
    ).then((res)=>{
     
          setIsPending(false)
        }).catch((err)=>{
          setIsPending(false)
          console.log(err)
        })
    
  }

  const crys = new web3.eth.Contract(
    nfts["crystals"]["abi"], 
    nfts["crystals"]["address"]) 

  const [isApproved, setIsApproved] = React.useState(false)
  const checkApprovalThen = (cb) => {
    resources.methods.isApprovedForAll(window.ethereum.selectedAddress, nfts["crystals"]["address"])
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setIsApproved(res)
        console.log(res)
        cb(res)
      }).catch((err)=>{
        console.log(err)
      })
  }

  

  const approve4all = () => {
    let data = resources.methods.setApprovalForAll(nfts["crystals"]["address"], true).encodeABI()
    window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["resources"]["address"],
            value: 0,
            data: data,
          },

        ],
    
      }
    )
  }

  const forge = (id) => {
    return () => {
      let data = crys.methods.forge(id, karmaCrystalRef.current.value).encodeABI()
      setIsPending(true)
      window.ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["crystals"]["address"],
            value: 0,
            data: data,
          },

        ],
    
      }).then((res)=>{
        setIsPending(false)

      }).catch((err)=>{
        setIsPending(false)
      })
      }
    
  }

  const karmaCrystalRef = React.createRef()

  const [balKarmaCrystal, setBalKarmaCrystal] = React.useState(0)
  
  const getCrystalBalance = (id, setState) => {
    crys.methods.balanceOf(window.ethereum.selectedAddress, id)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        console.log("crystal req", res)
        setState(res)
      }).catch((err)=> {
        console.log(err)
      })
  }


  const shrines = new web3.eth.Contract(
    nfts["shrines"]["abi"], 
    nfts["shrines"]["address"]) 
    
  const shrineAddy = nfts["shrines"]["address"];
  const approve4allCrystals = () => {
    let data = crys.methods.setApprovalForAll(shrineAddy, true).encodeABI()
    window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["crystals"]["address"],
            value: 0,
            data: data,
          },
        ],
      }
    )
  }

  const approveCandle4Shrines = () => {
    let data = candle.methods.approve(shrineAddy, "10000000000000000000000").encodeABI()
    window.ethereum.request(
    {
      method: "eth_sendTransaction",
      params: [
      {
        from: window.ethereum.selectedAddress,
        to: tokens["candle"]["address"],
        value: 0,
        data: data,
      },
      ],
      
    })
  }



  const purchaseShrine = () => {
    
    let data = shrines.methods.purchase().encodeABI()
     window.ethereum.request(
    {
      method: "eth_sendTransaction",
      params: [
      {
        from: window.ethereum.selectedAddress,
        to: nfts["shrines"]["address"],
        value: 0,
        data: data,
      },
      ],
      
    })
    
  }

   const shrineOwners = [
    "0x8DE3c3891268502F77DB7E876d727257DEc0F852",
    "0x36d481b3c6ceca782b1FE72a80D704dA06E6c063",
    "0x5bFaad4A04Ab0Fab6d206c9ea1144da886d31f9a",
    "0x5d02c857E98465f5b3a957B1f43569C4dAe58cA0",
    "0xA0035d1B65f408D605C2754b9aCD0E3bA4013202",
    "0xe5845761e7773976055F2559ca54be41F09d9E88",
    "0xd6B598C0D187c5d190f12f1034FBa11C300e06fA",
    "0xEE13Ae5Caf40f5757Ed890be6330788779984BfA",
    "0x6Fb966a32bE4C0d10A0329BDB1F01Dd89Ad264c2",
    "0xf09738069C21d1b874eF2d8F51812f5600304660",
    "0x656A41EeBec46A6f3709783c8C93c2E522d2AfEB"
  ];

  const shrineNames = [
    "The Devil's Shrine",
    "Happy Ending Shrine",
    "BOB",
    "Cult Dominator",
    "Sweet Shrine O' Mine",
    "Stonehenge",
    "Shrine o' Skullys",
    "1000 Corpses",
    "Woaw, am I a shrine?",
    "JesusWasHere",
    "NetMoney"
  ];

  const shrineFees = [
    13,
    100,
    20,
    30,
    100,
    70,
    50,
    25,
    20,
    42,
    20
  ];

  const [userShrine, setUserShrine] = React.useState(0)
  const [shrineFee, setShrineFee] = React.useState(0)
  const [shrineName, setShrineName] = React.useState("")
  const [userShrineOwner, setUserShrineOwner] = React.useState("")

  const managedShrineID = React.createRef()
  const checkShrine = () => {
    let id = managedShrineID.current.value
    setUserShrine(id)
    shrines.methods.getShrineFee(id)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setShrineFee(res)
      })
    shrines.methods.getShrineName(id)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setShrineName(res)
      })

    shrines.methods.getOwnerOfShrine(id)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setUserShrineOwner(res)
      })

    /*
    shrineOwners.forEach((owner, i) => {
      console.log(owner)
      if (owner.toLowerCase() == window.ethereum.selectedAddress.toLowerCase()) {
        let id = i+1
        setUserShrine(id)
        shrines.methods.getShrineFee(id)
          .call({from: window.ethereum.selectedAddress})
          .then((res)=>{
            setShrineFee(res)
          })
        shrines.methods.getShrineName(id)
          .call({from: window.ethereum.selectedAddress})
          .then((res)=>{
            setShrineName(res)
          })
      }
    })
    */
  }

  const feeRef = React.createRef()
  const nameRef = React.createRef()


  const changeFee = () => {
    let fee = (feeRef.current.value == 0) ? 0 : feeRef.current.value + "000000000000000000"
    let data = shrines.methods.changeFee(userShrine,fee).encodeABI()
    window.ethereum.request(
    {
      method: "eth_sendTransaction",
      params: [
      {
        from: window.ethereum.selectedAddress,
        to: nfts["shrines"]["address"],
        value: 0,
        data: data,
      },
      ],
      
    }).catch((err)=>{
      alert(err.data.message)
    })
  }

  const changeName = () => {
    let data = shrines.methods.setShrineName(userShrine,nameRef.current.value).encodeABI()
    window.ethereum.request(
    {
      method: "eth_sendTransaction",
      params: [
      {
        from: window.ethereum.selectedAddress,
        to: nfts["shrines"]["address"],
        value: 0,
        data: data,
      },
      ],
      
    }).catch((err)=>{
      alert(err.data.message)
    })
    
  }

  // shrine routing

  const alphaShrineRouter = new web3.eth.Contract(
    nfts["AlphaShrineRouter"]["abi"], 
    nfts["AlphaShrineRouter"]["address"]) 

  const shrineCrystals = new web3.eth.Contract(
    nfts["shrineCrystals"]["abi"], 
    nfts["shrineCrystals"]["address"]) 

const [balFamCrystal, setBalFamCrystal] = React.useState(0)

  const getShrineCrystalBalance = (id, setState) => {
    shrineCrystals.methods.balanceOf(window.ethereum.selectedAddress, id)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        console.log("crystal req", res)
        setState(res)
      }).catch((err)=> {
        console.log(err)
      })
  }

const forgeFamiliarCrystal = (id) => {
  return () => {
    let data = alphaShrineRouter.methods.enter(id,101).encodeABI()
    window.ethereum.request(
      {
        method: "eth_estimateGas",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["AlphaShrineRouter"]["address"],
   
            data: data,
          },

        ],
    
      }
    ).then((gasAmount)=>{
      window.ethereum.request({
            method: "eth_sendTransaction",
            params: [
            {
              from: window.ethereum.selectedAddress,
              to: nfts["AlphaShrineRouter"]["address"],
              value: 0,
              data: data,
            },
            ],
            
          })
    }).catch((err)=>{
      alert(err.data.message)
    })
    
  }
}


const checkShrineReady = (id) => {
  return () => {
    console.log("checking shrine",id)
    let data = alphaShrineRouter.methods.enter(id,101).encodeABI()


    window.ethereum.request(
      {
        method: "eth_estimateGas",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["AlphaShrineRouter"]["address"],
   
            data: data,
          },

        ],
    
      }
    ).then((gasAmount)=>{
        console.log(gasAmount)
        alert("Ready!")
      }).catch((error)=>{
        alert(error.data.message)
      })
    }
    
  
    
    
}

const checkShrineOccupied = (id) => {
  return () => {

  }
}


const [isApprovedForAllASR, setIsApprovedForAllASR] = React.useState(false)
const checkIApprovedForAllASR = () => {
  resources.methods.isApprovedForAll(window.ethereum.selectedAddress, nfts["AlphaShrineRouter"]["address"])
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      setIsApprovedForAllASR(res)
    })
}

const approve4allASR = () => {
  let data = resources.methods.setApprovalForAll(nfts["AlphaShrineRouter"]["address"], true).encodeABI()
  window.ethereum.request(
    {
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: nfts["resources"]["address"],
          value: 0,
          data: data,
        },

      ],
  
    }
  )
}

const revoke4allASR = () => {
  let data = resources.methods.setApprovalForAll(nfts["AlphaShrineRouter"]["address"], false).encodeABI()
  window.ethereum.request(
    {
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: nfts["resources"]["address"],
          value: 0,
          data: data,
        },

      ],
  
    }
  )
}
const approveCandle4ASR = () => {
    let data = candle.methods.approve(nfts["AlphaShrineRouter"]["address"], "100000000000000000000").encodeABI()
    window.ethereum.request(
    {
      method: "eth_sendTransaction",
      params: [
      {
        from: window.ethereum.selectedAddress,
        to: tokens["candle"]["address"],
        value: 0,
        data: data,
      },
      ],
      
    })
  }

  const leave = (id)=>{
    return () => {

       let data = alphaShrineRouter.methods.leave(id).encodeABI()

         window.ethereum.request(
        {
          method: "eth_estimateGas",
          params: [
            {
              from: window.ethereum.selectedAddress,
              to: nfts["AlphaShrineRouter"]["address"],
     
              data: data,
            },

          ],
      
        }
      ).then((gasAmount)=>{
          window.ethereum.request({
            method: "eth_sendTransaction",
            params: [
            {
              from: window.ethereum.selectedAddress,
              to: nfts["AlphaShrineRouter"]["address"],
              value: 0,
              data: data,
            },
            ],
            
          }).catch((err)=>{
            alert(err.data.message)
          })
      }).catch((error)=>{
        alert(error.data.message)
      })
      }
       
    
   
  }

  const refresh = (id)=>{
    return () => {
      shrines.methods.getShrineName(id)
        .call({from: window.ethereum.selectedAddress})
        .then((resName)=>{
          shrines.methods.getShrineFee(id)
            .call({from: window.ethereum.selectedAddress})
             .then((resFee)=>{
              alert(resName + " Shrine has a " + fs(resFee) + " $CANDLE fee")
             })
        })

    }
  }






  // ------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // Familiar Dust



  const fdf = new web3.eth.Contract(nfts["famdustfarm"]["abi"], nfts["famdustfarm"]["address"])

  const [startBlockFD, setStartBlockFD] = React.useState(0)
  const startMiningBlockFD = () => {
    fdf.methods.startMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setStartBlockFD(res)
      }).catch((err)=>{
        console.log(err)
      })
  }

  const [stopBlockFD, setStopBlockFD] = React.useState(0)
  const stopMiningBlockFD = () => {
    fdf.methods.stopMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setStopBlockFD(res)
      }).catch((err)=>{
        console.log(err)
      })
  }

  const isMiningFD = () => {

    if (startBlockFD > 0) {
      return true
    } else {
      return false
    }

  }

  const isWaitingFD = () => {
    if (stopBlockFD > 0) {
      return true
    } else {
      return false
    }
  }

  const fdTime = 1
  const fdDelay = 1000

  const [statusFD, setStatusFD] = React.useState("init")

  React.useEffect(()=>{
    if (isWaitingFD()) {
      // user is either on cooldown
      console.log(Number(stopBlockFD) + fdDelay)
      if (Number(stopBlockFD) + fdDelay > Number(currentBlock)) {
        setStatusFD("resting")
      } else { // or ready to mine again
        setStatusFD("ready")
      }
    } 

    if (isMiningFD()) {
      // user is either mining and has time left
      if (Number(startBlockFD) + fdTime > Number(currentBlock)) {
        setStatusFD("mining")
      } else { // or is finished mining but hasn't triggered rest()
        setStatusFD("tired")
      }
      
    }

    if (!isWaitingFD() && !isMiningFD() ) {
      // user first time
      setStatusFD("ready")
    }
  }, [startMiningBlockFD, stopMiningBlockFD])

  const mineFD = () => {
    return () => {
      let data = fdf.methods.mine().encodeABI()
      setIsPending(true)
      window.ethereum.request(
        {
        method: "eth_sendTransaction",
        // The following sends an EIP-1559 transaction. Legacy transactions are also supported.
        params: [
          {
            // The user's active address.
            from: window.ethereum.selectedAddress,
            // Required except during contract publications.
            to: nfts["famdustfarm"]["address"],
            // Only required to send ether to the recipient from the initiating external account.
            value: 0,
            data: data,
          },
        ],
      }

        ).then((res)=>{
          setIsPending(false)
      
        }).catch((err)=>{
          setIsPending(false)
          console.log(err)
        })
    }
  }

  const restFD = () => {
    let data = fdf.methods.rest().encodeABI()
    window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["famdustfarm"]["address"],
            value: 0,
            data: data,
          },

        ],
    
      }
    ).then((res)=>{
          setIsPending(false)
   
        }).catch((err)=>{
          setIsPending(false)
          console.log(err)
        })
    
  }

  const [balFD, setBalFD] = React.useState(0)

 

  

  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // CRYSTAL STAKING --------------------------------------------------------------------------

  const crystalStaking = new web3.eth.Contract(
    nfts["crystalStaking"]["abi"], 
    nfts["crystalStaking"]["address"])


  const stakeRef = React.createRef()
  const unstakeRef = React.createRef()

  const stakeCrystals = () => {

    let data = crystalStaking.methods.deposit(1,stakeRef.current.value).encodeABI()
    window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["crystalStaking"]["address"],
            value: 0,
            data: data,
          },

        ],
    
      }
    ).catch((err)=>{
      alert(err.data.message)
    })
  }

  const unstakeCrystals = () => {
    let data = crystalStaking.methods.withdraw(1,unstakeRef.current.value).encodeABI()
    window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["crystalStaking"]["address"],
            value: 0,
            data: data,
          },

        ],
    
      }
    ).catch((err)=>{
      alert(err.data.message)
    })
  }

  const claimRewards = () => {
    let data = crystalStaking.methods.harvest(1).encodeABI()
    window.ethereum.request(
      {
        method: "eth_estimateGas",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["crystalStaking"]["address"],
   
            data: data,
          },

        ],
    
      }
    ).then((gasAmount)=>{
      window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["crystalStaking"]["address"],
            value: 0,
            data: data,
          },

        ],
    
      }
    ).catch((err)=>{
      alert(err.data.message)
    })
    }).catch((err)=>{
      alert(err.data.message)
    })
    
  }

  const approve4allCS = () => {
  let data = shrineCrystals.methods.setApprovalForAll(nfts["crystalStaking"]["address"], true).encodeABI()
  window.ethereum.request(
    {
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: nfts["shrineCrystals"]["address"],
          value: 0,
          data: data,
        },
      ],
    }
  )
}

const [userAmountStaked, setUserAmountStaked] = React.useState(0)

const updateAmtStaked = () => {
  crystalStaking.methods.getUserInfoByAddressAndPool(window.ethereum.selectedAddress,1)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      setUserAmountStaked(Number(res.amountStaked))
      
    })
}




  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------

const stakeRef_k = React.createRef()
  const unstakeRef_k = React.createRef()

  const stakeCrystals_k = () => {

    let data = crystalStaking.methods.deposit(2,stakeRef_k.current.value).encodeABI()
    window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["crystalStaking"]["address"],
            value: 0,
            data: data,
          },

        ],
    
      }
    ).catch((err)=>{
      alert(err.data.message)
    })
  }

  const unstakeCrystals_k = () => {
    let data = crystalStaking.methods.withdraw(2,unstakeRef_k.current.value).encodeABI()
    window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["crystalStaking"]["address"],
            value: 0,
            data: data,
          },

        ],
    
      }
    ).catch((err)=>{
      alert(err.data.message)
    })
  }

  const claimRewards_k = () => {
    let data = crystalStaking.methods.harvest(2).encodeABI()
    window.ethereum.request(
      {
        method: "eth_estimateGas",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["crystalStaking"]["address"],
   
            data: data,
          },

        ],
    
      }
    ).then((gasAmount)=>{
      window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["crystalStaking"]["address"],
            value: 0,
            data: data,
          },

        ],
    
      }
    ).catch((err)=>{
      alert(err.data.message)
    })
    }).catch((err)=>{
      alert(err.data.message)
    })
    
  }

  const approve4allCS_k = () => {
  let data = crys.methods.setApprovalForAll(nfts["crystalStaking"]["address"], true).encodeABI()
  window.ethereum.request(
    {
      method: "eth_sendTransaction",
      params: [
        {
          from: window.ethereum.selectedAddress,
          to: nfts["crystals"]["address"],
          value: 0,
          data: data,
        },
      ],
    }
  )
}

const [userAmountStaked_k, setUserAmountStaked_k] = React.useState(0)

const updateAmtStaked_k = () => {
  crystalStaking.methods.getUserInfoByAddressAndPool(window.ethereum.selectedAddress,2)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      setUserAmountStaked_k(Number(res.amountStaked))
      
    })
}

 // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  return (
    <div className="App">
      <div className="the-forge"> 
        <div className="header">
          <div className="home">
            <div className="home__img"><img src={door}/></div>
            <div className="home__text">The Forge</div>
          </div>
        </div>
        <div className="menu">
          <div className="menu__item" onClick={nav("wallet")}>Wallet</div>
          <div className="menu__item" onClick={nav("resources")}>Mine Resources</div>
          <div className="menu__item" onClick={nav("forge")}>Forge Crystals & Relics</div>
          <div className="menu__item" onClick={nav("stake")}>Crystal Staking</div>
          <div className="menu__item" onClick={nav("ritual")}>Shrine Management</div>
          <div className="menu__item" onClick={nav("faq")}>FAQ</div>
        </div>

        <div id="page-resources" className={"page page--" + livePage}>
          <h1>Mining Hub</h1>
          <div className={"checker checker--" + status}>
            <button className="inline" onClick={checkStatus}>Check Status</button>
            <div className="inline">
              <p>Last Mined Resource: {Number(lastMined)}</p>
              <p>Current Block: {Number(currentBlock)}</p>
            </div>
          </div>
          <div className="resource"  onClick={setModal("karma")}>
            <div className="resource__img"><img src={karmaimg}/></div>
            <div className="resource__name">Karma</div>
          </div>
          <div className="resource" onClick={setModal("famdust")}>
            <div className="resource__img"><img src={dustimg}/></div>
            <div className="resource__name">Familiar Dust</div>
          </div>
          <div className="resource rh" onClick={setModal("rock")}>
            <div className="resource__img"><img src={dustimg}/></div>
            <div className="resource__name">Sacred Rock</div>
          </div>
          <div className="resource rh" onClick={setModal("bark")}>
            <div className="resource__img"><img src={dustimg}/></div>
            <div className="resource__name">Elder Bark</div>
          </div>
        </div>
        <div id="page-forge" className={"page page--" + livePage}>
          <h1>Forge Crystals and Relics</h1>
    
          <div className="resource" onClick={setModalKarma()}>
            <div className="resource__img"><img src={karmacrystal}/></div>
            <div className="resource__name">Karma Crystals</div>
          </div>
          <div className="resource" onClick={setModal("familiar-crystal")}>
            <div className="resource__img"><img src={famcrystal}/></div>
            <div className="resource__name">Familiar Crystals</div>
          </div>
          <div className="resource rh" onClick={setModal("ritual-chalk")}>
            <div className="resource__img"><img src={dustimg}/></div>
            <div className="resource__name">Ritual Chalk</div>
          </div>
          <div className="resource rh" onClick={setModal("wands")}>
            <div className="resource__img"><img src={dustimg}/></div>
            <div className="resource__name">Wands</div>
          </div>
          <div className="resource rh" onClick={setModal("spellbook")}>
            <div className="resource__img"><img src={dustimg}/></div>
            <div className="resource__name">Spellbook</div>
          </div>
        </div>
        <div id="page-stake" className={"page page--" + livePage}>
          <h1>Crystal Staking</h1>
        
          
          <div className="staking-pool">
            <div className="staking">
              <div className="staking__name">Familiar Crystal >> $CANDLE</div>
              <div className="staking__asset">
                <p>You have {userAmountStaked} Familiar Crystals staked</p>
                <div><input ref={stakeRef}/><button onClick={stakeCrystals}>Stake</button></div>
                <div><input ref={unstakeRef}/><button onClick={unstakeCrystals}>Unstake</button></div>
              </div>
              <button className="claim-cs" onClick={claimRewards}>Claim $CANDLE</button>
              <div><button onClick={updateAmtStaked}>Update Amount Staked</button>
              <button onClick={approve4allCS}>Approve Shrine Crystals</button></div>
            </div>
            
          </div>
          <div className="staking-pool">
          <div className="staking">
              <div className="staking__name">Karma Crystal >> $CANDLE</div>
              <div className="staking__asset">
                <p>You have {userAmountStaked_k} Karma Crystals staked</p>
                <div><input ref={stakeRef_k}/><button onClick={stakeCrystals_k}>Stake</button></div>
                <div><input ref={unstakeRef_k}/><button onClick={unstakeCrystals_k}>Unstake</button></div>
              </div>
              <button className="claim-cs" onClick={claimRewards_k}>Claim $CANDLE</button>
              <div><button onClick={updateAmtStaked_k}>Update Amount Staked</button>
              <button onClick={approve4allCS_k}>Approve Cult Crystals</button></div>
            </div>
            </div>
            <div className="staking-pool rh">
          <div className="staking">
              <div className="staking__name">$CANDLE for Karma Crystals</div>
              <div className="staking__asset">
                <p>You have 0 Karma Crystals staked</p>
                <button>Stake</button>
                <button>Unstake</button>
              </div>
              <div className="staking__reward"><p>You have 0 $CANDLE to claim</p></div>
              <button>Claim $CANDLE</button>

            </div>
            </div>
            <div className="scroll-help"></div>
        </div>
        <div id="page-ritual" className={"page page--" + livePage}>
          <h1>Shrine Management</h1>
      
          <div className="">
            {
              // <div className="ritual-buy">Choose your default <button onClick={setModal("ritual-forge")}>Shrine</button></div>
            } 
            <h2>Purchase</h2>
            <div className="ritual-buy">
              <div className="shrine-pic"><img src={figure} /></div>
              <p>Pay 42 Karma Crystals and 10,000 $CANDLE to purchase an Alpha Shrine.</p>
              <button onClick={approve4allCrystals}>Approve Karma Crystals</button>
              <button onClick={approveCandle4Shrines}>Approve 10,000 CANDLE</button>
              <button onClick={purchaseShrine}>Purchase</button>
            </div>
            <h2>Manage</h2>
            <div className="ritual-manage">
              <input ref={managedShrineID} />
              <button onClick={checkShrine}>Load Shrine</button>
              <div className={"shrine shrine--"+userShrine}>
                <h2>Shrine #{userShrine}</h2>
                <p>Shrine Owner: {userShrineOwner}</p>
                <p>Current Fee: {fs(shrineFee)} $CANDLE</p>
                <button onClick={changeFee}>Change Fee</button>
                <input ref={feeRef} />
                <p>Current Name: {shrineName}</p>
                <button onClick={changeName}>Change Name</button>
                <input ref={nameRef} />
              </div>
            </div>
            
          </div>
        </div>
        <div id="page-faq" className={"page page--" + livePage}>
          <h1>FAQ</h1>
          <div className="faq">
            <p className="q">What is the contract address for resources?</p>
            <p>Resources are an ERC1155 contract: 0xb363884d2393764f9721b16aab3c0289576ff243</p>
            <p className="q">What resources are currently live?</p>
            <p>There are two resources, <strong>Karma</strong> and Familiar Dust.</p>
            <p>Karma was released after some community testing. Testers were rewarded Karma for their help. </p>
            <p>Karma can be mined via the contract by anyone holding a Cult NFT. </p>
            <p>It will be rewarded again in the future for more tests.</p>
            <p>Familiar Dust is mineable by Familiars.</p>
            <p className="q">What resources are planned?</p>
            <p>Alot. We will role out new chapters soon. During week 1 we will introduce <strong>Sacred Rock</strong> and during week 2 we will introduce <strong>Elder Bark</strong>.</p>
            <p className="q">What are Crystals and Relics?</p>
            <p>Crystals (0xeE5c70923934232a4a1F94462F9955e2B8A58792 and 0xaaa45f719c3c519dbd890e34e83f26a9682dd4df) and Relics are ERC1155s created by combining Resources. <strong>Karma Crystals</strong> can be made by combining Karma. Familiar Crystals can be made by combining Familiar Dust.</p>
            <p>During the Prologue launch, Sacred Rock will be used to forge <strong>Ritual Chalk</strong> and Elder Bark will be used to forge <strong>Wands</strong>. Both will be combined to forge <strong>Spellbooks</strong>.</p>
            <p className="q">What is a Shrine?</p>
            <p>Familiar Crystals, and other future Crystals and Relics, require use of a Shrine to Craft.</p>
            <p className="q">How much does a Shrine cost?</p>
            <p>There are 4 tiers. The first tier, Alpha Shrines, cost 42 Karma Crystals and 10,000 CANDLE.</p>
            <p className="q">What happens to the funds spent on a Shrine?</p>
            <p>The Karma Crystals are burned and the CANDLE goes to the DAO.</p>
            <p className="q">Why buy a Shrine?</p>
            <p>Each Shrine has a fee of 0–100 $CANDLE per Crystal/Relic set by the owner.</p>
            <p>The owner receives 99% of the fee and the DAO multisig receives the rest.</p>
            <p className="q">Why does there need to be multiple Shrines?</p>
            <p>Only 1 user can use a Shrine at any given moment. If a user has begun forging, and has their Crystals/Relics pending, another user can trigger the start of their forging, paying the gas to mint the Crystals/Relics to the prior forger.</p>
          </div>
        </div>

      </div>

      <div id="page-wallet" className={"page page--" + livePage}>
        <h1>Wallet</h1>
        <button onClick={loadBalances}>Load Balances</button>
        <p>$CANDLE: {fs(balCandle)}</p>
        <p>Karma: {Number(balKarma)}</p>
        <p>Familiar Dust: {Number(balFD)}</p>
        <p>Karma Crystals: {Number(balKarmaCrystal)}</p>
        <p>Familiar Crystals: {Number(balFamCrystal)}</p>
      </div>

      <div className={"modal modal--" + modalOn}>

        <div className={"modal-page karma modal-page--" + activeModalPage}>
          <h2>Mine Resource: Karma</h2>
  
          
          <div className="blob">
            <p>Mining Time: 1 Block</p>
            <p>Mining Delay: 10,000 Block</p>
            <p>Mining Weights: [1,...,1]</p>
            <p>Mining Maxes: [1,...,1]</p>
            <p>You personally earn {Number(userKarmaRate)} Karma per mine</p>
          </div>

          <div className="action">
            <button onClick={checkStatus}>Check Status</button>
            <p>You have <strong>{Number(balKarma)}</strong> Karma</p>
            <div className={"msg ready msg--" + status}>
              <p>You are ready to start mining.</p>
              <button onClick={mine(1)}>Mine</button>
            </div>
            <div className={"msg resting msg--" + status}>
              <p>You are resting since block {Number(stopBlock)}.</p>
              <p><strong>{Number(stopBlock) + 10000 - Number(currentBlock)}</strong> blocks left until you can mine again.</p>
            </div>
            <div className={"msg tired msg--" + status}>
              <p>You are finished mining Karma. Time to rest.</p>
              <button onClick={rest}>Rest</button>
            </div>
            <div className={"msg mining msg--" + status}>
              <p>Wait until you are finished mining.</p>
              <p>You started mining at {startBlock} and the current block is {currentBlock}.</p>
              <p>If you are mining karma, you should be done at block {Number(startBlock) + resourceTimes[1]}.</p>
            </div>
            
            
          </div>
        </div>
        <div className={"modal-page famdust modal-page--" + activeModalPage}>
          <h2>Mine Resource: Familiar Dust</h2>
          <div className="blob">
            <p>Mining Time: 1 Blocks</p>
            <p>Mining Delay: 1000 Blocks</p>
            <p>Mining Weights: Only Familiars</p>
            <p>Mining Maxes: No Max</p>
          </div>
          <p>Familiar Dust is used to forge Familiar Crystals</p>
          <div className="action">
            <button onClick={checkStatus}>Check Status</button>
            <p>You have <strong>{Number(balFD)}</strong> Familiar Dust</p>
            <div className={"msg ready msg--" + statusFD}>
              <p>You are ready to start mining.</p>
              <button onClick={mineFD()}>Mine</button>
            </div>
            <div className={"msg resting msg--" + statusFD}>
              <p>You are resting since block {Number(stopBlockFD)}.</p>
              <p><strong>{Number(stopBlockFD) + 1000 - Number(currentBlock)}</strong> blocks left until you can mine again.</p>
            </div>
            <div className={"msg tired msg--" + statusFD}>
              <p>You are finished mining Familiar Dust. Time to rest.</p>
              <button onClick={restFD}>Rest</button>
            </div>
            <div className={"msg mining msg--" + statusFD}>
              <p>Wait until you are finished mining.</p>
              <p>You started mining at {startBlock} and the current block is {currentBlock}.</p>
              <p>You should be done at block {Number(startBlock) + fdTime}.</p>
            </div>
            
            
          </div>
        </div>
        <div className={"modal-page rock modal-page--" + activeModalPage}>
          <h2>Mine Resource: Sacred Rock</h2>
          <p>Sacred Rock will be launching this summer</p>
          <div className="blob">
            <p>Mining Time: 1 Blocks</p>
            <p>Mining Delay: 1 Day</p>
            <p>Mining Weights: [3,1,...,1]</p>
            <p>Mining Maxes: [9,9,1,...,1]</p>
          </div>
          <p>If you have 9 Skullys, 9 Pop Skullys, and every other NFT, you earn 45 Rock per Day.</p>
          <p>Sacred Rock is used to craft Ritual Chalk and Spellbooks.</p>
        </div>
        <div className={"modal-page bark modal-page--" + activeModalPage}>
          <h2>Mine Resource: Elder Bark</h2>
          <p>Elder Bark will be launching this summer</p>
          <div className="blob">
            <p>Mining Time: 1 Blocks</p>
            <p>Mining Delay: 1 Day</p>
            <p>Mining Weights: Familiars Only</p>
            <p>Mining Maxes: No Max</p>
          </div>
          <p>There are only 100,000 Elder Bark in existence.</p>
          <p>Elder Bark are used to craft Wands and Spellbooks</p>
        </div>

        <div className={"modal-page karma-crystal modal-page--" + activeModalPage}>
          <h2>Forge Karma Crystals</h2>
          <p>You have <strong>{Number(balKarmaCrystal)}</strong> Karma Crystals</p>
          <div className="action">
            <p>You have <strong>{Number(balKarma)}</strong> Karma</p>
            <p>At the current rate, 32 Karma can be converted to 1 Karma Crystal</p>
            <p className={"isApproved isApproved--" + isApproved}>
              You need to approve the Crystal contract to spend your Karma.
              <button onClick={approve4all}>Approve</button>
            </p>
            <div>Forge<input ref={karmaCrystalRef} /> Karma Crystals</div><div><button onClick={forge(1)}>Forge</button></div>
          </div>
          <p>Karma Crystals can be used to craft Shrines.</p>
        </div>
         <div className={"modal-page familiar-crystal modal-page--" + activeModalPage}>
          <h2>Forge Familiar Crystals</h2>
          <p>You have <strong>{/*Number(balFamCrystal)*/}</strong> Familiar Crystals</p>
          <div className="action">
            <p>You have {Number(balFD)} Familiar Dust</p>
            <p>At the current rate, 32 Familiar Dust can be converted to 2 Familiar Crystal at an alpha shrine.</p>
            <p>Give/Check ApprovedForAll status: <button onClick={checkIApprovedForAllASR}>Check</button> {isApprovedForAllASR.toString()} <button onClick={approve4allASR}>Approve</button></p>
            <p>Revoke ApprovedForAll:  <button onClick={revoke4allASR}>Revoke</button></p>
            <div><button onClick={setModal("ritual-forge")}>Forge 2 Familiar Crystal</button></div>
          </div>
        </div>
        <div className={"modal-page ritual-chalk modal-page--" + activeModalPage}>
          <h2>Forge Ritual Chalk</h2>
          <p>Ritual Chalk will be launching this summer</p>
          <div className="action">
            <p>You have 0 Sacred Rock</p>
            <p>At the current rate, 10 Sacred Rock can be converted to 1 Ritual Chalk</p>
            <div>Forge<input /> Ritual Chalk</div><div><button>Forge</button></div>
          </div>
          <p>Ritual Chalk is consumed when minting a Summoner.</p>
        </div>
        <div className={"modal-page wands modal-page--" + activeModalPage}>
          <h2>Forge Wands</h2>
          <p>Wands will be launching this summer</p>
          <div className="action">
            <p>You have 0 Elder Bark</p>
            <p>At the current rate, 10 Elder Bark can be converted to 1 Wand</p>
            <div>Forge<input /> Wands</div><div><button>Forge</button></div>
          </div>
          <p>Wands are required to mint a Summoner.</p>
        </div>
        <div className={"modal-page spellbook modal-page--" + activeModalPage}>
          <h2>Forge Spellbooks</h2>
          <p>Spellbooks will be launching this summer</p>
          <div className="action">
            <p>You have 0 Elder Bark and 0 Sacred Rock</p>
            <p>At the current rate, 10 Elder Bark and 100 Sacred Rock can be converted to 1 Spellbook</p>
            <div>Forge<input /> Spellbooks</div><div><button>Forge</button></div>
          </div>
          <p>Spellbooks are required to mint a Summoner.</p>
        </div>

        <div className={"modal-page ritual-forge modal-page--" + activeModalPage}>
          <h2>Alpha Shrines</h2>
          <table>
            <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>$CANDLE Fee</th>
           
              <th>Ready</th>
              <th>Approve CANDLE</th>
              <th>Enter</th>
              <th>Leave</th>
              <th>Fee Check</th>
            </tr>
            </thead>
            <tbody>
            {
              shrineNames.map(
                (name, i)=>(
                  <tr key={"shrine-"+i} className="shrine-row">
                    <td>{i+1}</td>
                    <td>{name}</td>
                    <td>{shrineFees[i]}</td>
      
                    <td><button onClick={checkShrineReady(i+1)}>Check</button></td>
                    <td><button onClick={approveCandle4ASR}>Approve</button></td>
                    <td><button onClick={forgeFamiliarCrystal(i+1)}>Enter</button></td>
                    <td><button onClick={leave(i+1)}>Leave</button></td>
                    <td><button onClick={refresh(i+1)}>Check Current Fee</button></td>
                  </tr>
                    )

                )
            }
            
           
            </tbody>
          </table>
        </div>









        <button className="close" onClick={()=>{
          setModalOn(false)
        }}>close</button>
      </div>
      
      <div className={"pending pending--" + isPending}>Pending Transaction</div>
      <button 
        disabled={isDisabled} 
        onClick={connectMM} 
        className="mmbtn">
        {mmBtnText}
      </button>
    </div>
  );
}

export default App;
